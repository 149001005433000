(function($) {
    $(document).ready(function($) {
        $('#form_wrap').css({'opacity': 1}).fadeIn('200');

        // Perform AJAX login on form submit
        $('#cia_login').on('submit', function(e){
            var icon = $('.login-icon');

            $.urlParam = function (name) {
                var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.search);
                return (results !== null) ? results[1] || 0 : false;
            }
            var urlParams = $.urlParam('og_source');
            
            $.ajax({
                type: 'POST',
                dataType: 'json',
                cache: false,
                url: ajax_login_object.ajaxurl,
                data: {
                    'action': 'cia_login',
                    'password': $('#cia_login #password').val(),
                    'security': ajax_login_object.nonce },
                beforeSend: function(){
                    icon.removeClass('fa-arrow-right').addClass('fa-cog fa-spin');
                },
                success: function(res){
                    if (res.success && res.data.logged === true){
                        icon.removeClass('fa-cog fa-spin').addClass('fa-hand-peace-o animated zoomIn');

                        console.log(urlParams);


                        if(urlParams){
                            window.location.href = urlParams;

                        } else{
                            location.href = "/cases/";
                        }

                        /*
                        $.ajax({
                            type: 'POST',
                            cache: false,
                            url: ajax_login_object.ajaxurl,
                            data: {
                                'action': 'cia_content',
                            },
                            success: function(content_data){
                                $('#ajax_box').html(content_data);

                                //isotope grid
                                $grid = $('#cases');

                                $grid.imagesLoaded(function(){

                                    $('.category-select').fadeIn('200');
                                    $('#search-form').fadeIn('200');

                                    $grid.hide().fadeIn('200').isotope({
                                      itemSelector: '.block'
                                    });

                                });

                                var select = $('#category');
                                select.on('change', function(){
                                  var option = ($(this).val() === '*') ? '*' : ('.' + $(this).val());
                                  $grid.isotope({ filter: option });
                                });
                            }
                        });
                        */
                    }else{
                        $('#cia_login #password').val('');
                        icon.removeClass('fa-cog fa-spin').addClass('fa-arrow-right');
                        $('#cia_login').addClass('shake').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
                            $(this).removeClass('shake');
                        });
                    }
                }
            });
            e.preventDefault();
        });

    });
})(jQuery);
